<template>
  <FormGroup class="FormWysiwyg" :id="id" :label="label" :validator="validator" :hint="hint">
    <div
      ref="editor"
      class="form-control FormWysiwyg__editor"
      contentEditable="true"
      v-html="model"
      v-once
      :name="id"
      :id="id"
      :class="[ 'form-control-' + size, { 'is-invalid': validator && validator.$error } ]"
      :value="model"
      @input="onUpdateValue"
    />
    <button class="FormWysiwyg__button" type="button" @click="addBold">Bold</button>
  </FormGroup>
</template>

<script>
  import generateId from '@/shared/utils/generateId'
  import FormGroup from '@/components/base/FormGroup'

  export default {
    components: {
      FormGroup
    },

    props: {
      label: {
        type: String,
        default: ''
      },
      hint: {
        type: String,
        default: ''
      },
      size: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: 'text'
      },
      validator: {
        type: Object,
        default: null
      },
      modelValue: {
        type: String,
        default: ''
      }
    },

    emits: [ 'update:modelValue' ],

    data() {
      return {
        id: generateId(),
        model: null
      }
    },

    watch: {
      validator: {
        immediate: true,
        deep: true,
        handler(validator) {
          if (validator) {
            this.model = validator.$model
          }
        }
      },

      modelValue: {
        immediate: true,
        handler(modelValue) {
          if (!this.validator) {
            this.model = modelValue
          }
        }
      }
    },

    mounted() {
      const editor = this.$refs.editor

      editor.addEventListener('paste', (e) => {
        let data = e.clipboardData.getData('text/html') || e.clipboardData.getData('text/plain')

        data = this.cleanHTML(data)
        document.execCommand('insertHTML', false, data)
        e.preventDefault()
      })
    },

    methods: {
      addBold() {
        document.execCommand('bold')
      },

      onUpdateValue(e) {
        const html = this.cleanHTML(e.target.innerHTML)

        if (this.validator) {
          // eslint-disable-next-line vue/no-mutating-props
          this.validator.$model = html
        }
        this.$emit('update:modelValue', html)
      },

      cleanHTML(html) {
        const regex = /<(?!(\/\s*)?(b|strong|br)[>,\s])([^>])*>/g

        html = html.trim()
        html = html.replace(/<div>/g, '<br />')
        html = html.replace(regex, '')

        return html
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all'

  .FormWysiwyg
    position: relative

  .FormWysiwyg__editor
    overflow: auto

  .FormWysiwyg__button
    position: absolute
    top: .25em
    right: 0
    appearance: none
    border: none
    background: transparent
    font-size: 9px
    text-transform: uppercase
    border: 1px solid $primary
    color: $primary
    padding: 2px 5px 0px
    border-radius: 2px
</style>

