<template>
  <main class="SettingstView">
    <div class="container">
      <header class="mb-3">
        <h1>Settings</h1>
      </header>

      <AppLoading v-if="loading" />

      <form novalidate class="form" @submit.prevent="onSubmit" v-if="!loading">
        <div class="card mb-3" v-if="formError">
          <div class="card-body">
            <div class="alert alert-danger">
              {{ formError }}}}
            </div>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-header">
            <h2>Invoices</h2>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <FormInput label="Tax ID" :validator="v$.form.invoice_tax_id" />
                </div>

                <div class="mb-3">
                  <FormInput label="Contact email" :validator="v$.form.invoice_contact_email" />
                </div>

                <div class="mb-3">
                  <FormInput label="Contact website" :validator="v$.form.invoice_website" />
                </div>

                <div class="mb-3">
                  <FormInput label="Footer" :validator="v$.form.invoice_footer" />
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <FormInput type="number" step="any" label="Performance fees" :validator="v$.form.invoice_performance_fee" />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3">
                      <FormInput type="number" step="any" label="Quarterly fees" :validator="v$.form.invoice_quaterly_fee" />
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <FormInput label="VAT Info" :validator="v$.form.invoice_vat_info" />
                </div>
                <div class="mb-3">
                  <FormInput label="USDT address" :validator="v$.form.invoice_usdt_address" />
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <FormWysiwyg class="SettingstView__invoices__wysiwyg" label="USD Bank accountest" :validator="v$.form.invoice_usd_bank_account" />
                </div>

                <div class="mb-3">
                  <FormWysiwyg class="SettingstView__invoices__wysiwyg" label="EUR Bank account" :validator="v$.form.invoice_eu_bank_account" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-header">
            <h2>Emails</h2>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <FormInput label="Title" :validator="v$.form.email_title" />
                </div>

                <div class="mb-3">
                  <FormWysiwyg class="SettingstView__emails__wysiwyg" label="Header" :validator="v$.form.email_intro" />
                </div>

                <div class="mb-3">
                  <FormWysiwyg class="SettingstView__emails__wysiwyg" label="footer" :validator="v$.form.email_footer" />
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <FormWysiwyg class="SettingstView__emails__wysiwyg--long" label="Body" :validator="v$.form.email_body" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-body text-end">
            <button class="btn btn-primary" :disabled="v$.form.$invalid">Save settings</button>
          </div>
        </div>
      </form>
    </div>
  </main>
</template>

<script>
  import AppLoading from '@/components/AppLoading'
  import FormInput from '@/components/base/FormInput'
  import FormWysiwyg from '@/components/base/FormWysiwyg'

  import settingsService from '@/services/settings.service'

  import useToast from '@/plugins/toast/useToast'
  import useVuelidate from '@vuelidate/core'
  import { required, email } from '@vuelidate/validators'
  import { mapState } from 'vuex'

  export default {
    components: {
      AppLoading,
      FormInput,
      FormWysiwyg
    },

    setup() {
      return {
        v$: useVuelidate(),
        toast$: useToast()
      }
    },

    data() {
      return {
        loading: true,
        settings: null,
        formError: null,
        form: {}
      }
    },

    validations() {
      return {
        form: {
          invoice_tax_id: { required },
          invoice_footer: { required },
          invoice_contact_email: { required, email },
          invoice_website: { required },
          invoice_usd_bank_account: { required },
          invoice_eu_bank_account: { required },
          invoice_performance_fee: { required },
          invoice_quaterly_fee: { required },
          invoice_vat_info: { required },
          invoice_usdt_address: { required },

          email_title: { required },
          email_intro: { required },
          email_body: { required },
          email_footer: { required }
        }
      }
    },

    computed: {
      ...mapState('auth', [
        'authenticated'
      ])
    },

    mounted() {
      this.load()
    },

    methods: {
      async load() {
        this.loading = true
        this.form = await settingsService.fetch()
        this.loading = false
      },

      async onSubmit() {
        this.formError = null
        this.v$.form.$touch()

        try {
          settingsService.save(this.form)
          this.toast$.success('Success!', 'Settings have been saved.')
        } catch (e) {
          this.formError = 'Something went wrong…'
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .SettingstView__invoices__wysiwyg
    :deep(.FormWysiwyg__editor)
      font-size: 11px
      height: 248px

  .SettingstView__emails__wysiwyg
    :deep(.FormWysiwyg__editor)
      font-size: 11px
      height: 100px

  .SettingstView__emails__wysiwyg--long
    :deep(.FormWysiwyg__editor)
      font-size: 11px
      height: 322px

</style>
